import user from './user'
import { contactRef } from '@/firebase/db'
export default {
  mixins: [user],
  methods: {
    async addMessage(data) {
      await contactRef.add({ ...data, seen: false })
    },
    async sendMessage(message) {
      let data = { ...message }
      let user = this.$store.getters.user
      data.user = {
        email: user.email,
        first_name: user.profile.first_name,
        last_name: user.profile.last_name,
        uid: await this.currentUser().uid
      }
      data.created_at = new Date()
      data.seen = false
      data.build_date = new Date(process.env.VUE_APP_BUILD_DATE)
      data.timestamp = new Date()
      await this.addMessage(data)
    }
  }
}
