var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogs.history),callback:function ($$v) {_vm.$set(_vm.dialogs, "history", $$v)},expression:"dialogs.history"}},[_c('v-card',[_c('v-card-title',[_vm._v(" History "),_c('v-icon',{staticClass:"ml-3",attrs:{"color":"info"}},[_vm._v("mdi-history")])],1),_c('v-card-text',[_c('parcel-history',{attrs:{"parcel":_vm.activeParcel}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogs.card),callback:function ($$v) {_vm.$set(_vm.dialogs, "card", $$v)},expression:"dialogs.card"}},[_c('parcel-detail',{attrs:{"data":_vm.activeParcel,"showClose":true},on:{"close":function($event){_vm.dialogs.card = false},"update":_vm.updateCurrent}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('base-data-table',{attrs:{"options":_vm.options,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.parcelsFiltered},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.team",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)+" "),_c('table-filter',{attrs:{"defaultColor":"","items":_vm.teamsComputedMap},model:{value:(_vm.teamFilter),callback:function ($$v) {_vm.teamFilter=$$v},expression:"teamFilter"}})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.removePadding(item.number))+" ")]}},{key:"item.solar_system_count",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.metrics && item.metrics.solar ? item.metrics.solar.count : '')+" ")])]}},{key:"item.total_panels",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).numberOfPanels)+" ")])]}},{key:"item.total_kw",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).kw)+" ")])]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).actualCost || _vm.getSolar(item).estimatedCost)+" ")])]}},{key:"item.first_install_year",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getFirstInstallYear(_vm.getSolar(item)))+" ")])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('property-card-key',{attrs:{"data":item,"width":80},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span')]},proxy:true}],null,true)})],1)]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('update-team',{attrs:{"data":_vm.getParcel(item)}})]}},{key:"item.recent_activity",fn:function(ref){
var item = ref.item;
return [(item.recent_activity)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.recent_activity)?_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.parseActivity(item.recent_activity))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.recent_activity.timestamp))+" ")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.showCardDialog(item)}}},on),[_vm._v("mdi-card-text")])]}}],null,true)},[_c('span',[_vm._v("Card View")])]),(!_vm.solarAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"secondary"},on:{"click":function($event){return _vm.showHistory(item)}}},on),[_vm._v("mdi-history")])]}}],null,true)},[_c('span',[_vm._v("History")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/parcel/" + (item.id) + "/solar"),"icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }