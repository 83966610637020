<template>
  <div>
    <v-dialog max-width="600" v-model="dialogs.history">
      <v-card>
        <v-card-title>
          History <v-icon class="ml-3" color="info">mdi-history</v-icon>
        </v-card-title>
        <v-card-text>
          <parcel-history :parcel="activeParcel"></parcel-history>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.card" max-width="600">
      <parcel-detail
        :data="activeParcel"
        @close="dialogs.card = false"
        @update="updateCurrent"
        :showClose="true"
      >
      </parcel-detail>
    </v-dialog>
    <v-row>
      <v-col cols="6" sm="6">
        <v-text-field
          append-icon="mdi-magnify"
          single-line
          label="Search"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <base-data-table
      :options.sync="options"
      :search="search"
      :loading="loading"
      :headers="headers"
      :items="parcelsFiltered"
    >
      <template v-slot:[`header.team`]="{ header }"
        >{{ header.text }}
        <table-filter
          defaultColor=""
          v-model="teamFilter"
          :items="teamsComputedMap"
        ></table-filter>
      </template>

      <template v-slot:[`item.number`]="{ item }">
        {{ removePadding(item.number) }}
      </template>

      <!-- For solar admin -->
      <template v-slot:[`item.solar_system_count`]="{ item }">
        <div>
          {{
            item.metrics && item.metrics.solar ? item.metrics.solar.count : ''
          }}
        </div>
      </template>
      <template v-slot:[`item.total_panels`]="{ item }">
        <div>
          {{ getSolar(item).numberOfPanels }}
        </div>
      </template>
      <template v-slot:[`item.total_kw`]="{ item }">
        <div>
          {{ getSolar(item).kw }}
        </div>
      </template>
      <template v-slot:[`item.total_cost`]="{ item }">
        <div>
          {{ getSolar(item).actualCost || getSolar(item).estimatedCost }}
        </div>
      </template>
      <template v-slot:[`item.first_install_year`]="{ item }">
        <div>
          {{ getFirstInstallYear(getSolar(item)) }}
        </div>
      </template>

      <template v-slot:[`item.key`]="{ item }">
        <div>
          <property-card-key :data="item" :width="80"
            ><template #text><span></span></template
          ></property-card-key>
        </div>
      </template>
      <template v-slot:[`item.team`]="{ item }">
        <update-team :data="getParcel(item)"></update-team>
      </template>
      <template v-slot:[`item.recent_activity`]="{ item }">
        <v-tooltip v-if="item.recent_activity" bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" v-if="item.recent_activity">
              {{ parseActivity(item.recent_activity) }}
            </span>
          </template>
          <span>
            {{ formatTimestamp(item.recent_activity.timestamp) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" @click="showCardDialog(item)" v-on="on"
                >mdi-card-text</v-icon
              >
            </template>
            <span>Card View</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!solarAdmin">
            <template v-slot:activator="{ on }">
              <v-icon color="secondary" @click="showHistory(item)" v-on="on"
                >mdi-history</v-icon
              >
            </template>
            <span>History</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :to="`/parcel/${item.id}/solar`" icon
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
        </div>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel'
import team from '@/mixins/team'
import solar from '@/mixins/solar'
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [parcel, team, solar],
  components: {
    PropertyCardKey: () => import('./PropertyCardKey'),
    UpdateTeam: () => import('./UpdateTeam'),
    ParcelDetail: () => import('./Detail'),
    ParcelHistory: () => import('./History.vue')
  },
  data: () => ({
    search: '',
    dialogs: {
      card: false,
      history: false
    },
    options: {},
    activeParcel: null
  }),
  computed: {
    headers() {
      let headers = [
        { text: 'Parcel ID', value: 'number' },
        { text: 'Address', value: 'address' },
        { text: 'Type', value: 'parcel_type' },
        { text: 'Acres', value: 'acres', align: 'center' },
        { text: 'Key', value: 'key' },
        { text: 'Last Activity', value: 'recent_activity' },
        { text: 'Action', value: 'action' }
      ]
      if (this.user.profile.enableTeams) {
        headers.splice(6, 0, { text: 'Team', value: 'team' })
      }
      if (this.user.profile.includeParcelReference) {
        headers.splice(5, 0, { text: 'Reference', value: 'reference' })
      }

      if (this.solarAdmin) {
        headers = [
          { text: 'Parcel ID', value: 'number' },
          {
            text: 'Solar Systems Count',
            value: 'solar_system_count',
            align: 'center'
          },
          { text: 'Total Panels', value: 'total_panels', align: 'center' },
          { text: 'Total kW', value: 'total_kw', align: 'center' },
          { text: 'Total Cost', value: 'total_cost', align: 'center' },
          {
            text: 'First Install Year',
            value: 'first_install_year',
            align: 'center'
          },
          { text: 'Action', value: 'action' }
        ]
      }
      return headers
    },
    loading: {
      get() {
        return this.$store.getters.parcelLoading
      },
      set(val) {
        this.$store.commit('parcelLoading', val)
      }
    },
    teams() {
      if (!this.$store.getters.teams) {
        return []
      }
      return [
        { text: 'All', value: '' },
        ...this.$store.getters.teams.data,
        { text: 'n/a', value: 'na' }
      ]
    },
    ...mapGetters({
      user: 'user',
      solarDataList: 'solar/dataList',
      solarAdmin: 'isSolarAdmin'
    })
  },
  async mounted() {
    if (this.solarAdmin) {
      this.getAllSolarData()
    }
  },
  methods: {
    ...mapMutations({
      addSolarDataList: 'solar/addDataList'
    }),
    getParcel(parcel) {
      return {
        ...this.$store.getters.parcels.filter(e => e.id == parcel.id)[0]
      }
    },
    setActiveParcel(parcel) {
      this.activeParcel = this.getParcel(parcel)
    },
    showCardDialog(parcel) {
      this.setActiveParcel(parcel)
      this.dialogs.card = true
    },
    showHistory(parcel) {
      this.setActiveParcel(parcel)
      this.dialogs.history = true
    },
    updateCurrent(data) {
      this.activeParcel = { ...data }
    },
    formatData(data) {
      data.address = (data.street_number || '') + ' ' + (data.street_name || '')
      data.parcel_type = this.getParcelTypeText(data.parcel_type)
      return data
    },
    getSolar(parcel) {
      let res = this.solarDataList.filter(e => e.id == parcel.id)[0]
      if (!res) {
        return {}
      }
      res = res.solar_systems[0]
      return res || {}
    },
    getFirstInstallYear(data) {
      let date = ''
      if (data.finalBuildingDate && data.finalWiringDate) {
        date = _.max([
          new Date(data.finalBuildingDate),
          new Date(data.finalWiringDate)
        ])
      } else if (data.finalBuildingDate) {
        date = new Date(data.finalBuildingDate)
      } else if (data.finalWiringDate) {
        date = new Date(data.finalWiringDate)
      } else {
        date = ''
      }
      if (date) {
        date = date.toISOString().split('T')[0]
      }
      return date
    }
  }
}
</script>
