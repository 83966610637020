<template>
  <div v-if="parcelsComputed.length">
    <v-row>
      <v-col
        sm="6"
        md="4"
        lg="3"
        xl="2"
        cols="12"
        v-for="(parcel, index) in parcelsComputed"
        :key="index"
      >
        <parcel-detail
          @update="$emit('update')"
          v-if="parcel"
          :data="parcel"
        ></parcel-detail>
      </v-col>
    </v-row>
    <div
      class="text-center mt-3"
      v-if="
        parcels.length > MAX_PARCEL_SHOW &&
        parcels.length != parcelsComputed.length
      "
    >
      <v-badge overlap :content="parcels.length - show" color="success">
        <v-btn large outlined color="secondary" @click="loadMore()"
          >Load More <v-icon>mdi-chevron-down</v-icon></v-btn
        >
      </v-badge>
    </div>
  </div>
  <v-row v-else-if="loading">
    <v-col
      v-for="(i, index) in 4"
      :key="index"
      cols="12"
      sm="6"
      md="4"
      lg="3"
      xl="2"
    >
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import ParcelDetail from './Detail.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    ParcelDetail
  },
  data: () => ({
    index: 0,
    MAX_PARCEL_SHOW: 10,
    show: 10
  }),
  computed: {
    ...mapGetters({
      parcels: 'parcels'
    }),
    parcelsComputed() {
      return this.parcels.slice(this.index, this.show)
    },
    loading: {
      get() {
        return this.$store.getters.parcelLoading
      },
      set(val) {
        this.$store.commit('parcelLoading', val)
      }
    }
  },
  methods: {
    loadMore() {
      this.show += 10
    }
  }
}
</script>

<style></style>
