<template>
  <v-main>
    <v-dialog v-model="dialogs.createParcel" max-width="800">
      <v-card class="mx-auto">
        <v-card-title
          >Add New Parcel <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.createParcel = !dialogs.createParcel"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >

        <v-card-text>
          <parcel-create
            @created="parcelCreated"
            @cancel="dialogs.createParcel = false"
          ></parcel-create>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogs.hoorayDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <peeps-hooray>
        <template v-slot:baseTitle>
          <span class="peeps--font">Congratulations!</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.hoorayDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </template>
        <div style="font-size: 0.95rem">
          <p>
            You just added your parcel to your Peeps Portal. If you have more
            than one parcel, you can add that one as well.
          </p>
          <p>
            And now just click one of the buttons below to get started on
            updating information on your property. Start anywhere you like - all
            the features have been designed to be easy to use, and will be
            accessible to you anytime, anywhere, and on any device you want to
            use such as your desktop, laptop, tablet and/or smartphone.
          </p>
        </div>
        <div class="d-flex justify-center" v-if="!latestParcel">
          <v-skeleton-loader
            class="ml-3"
            v-for="i in 3"
            :key="i"
            type="button"
          ></v-skeleton-loader>
        </div>
        <div v-else class="text-center">
          <span v-for="(feature, index) in featuresList" :key="index">
            <feature-button
              class="mr-2 mt-2"
              v-if="isFeatureAvailable(feature.feature)"
              :value="feature.feature"
              :to="featureLink(feature)"
              large
            ></feature-button>
          </span>
        </div>
      </peeps-hooray>
    </v-dialog>
    <v-container fluid>
      <div class="d-flex justify-space-between">
        <div>
          <div class="d-flex flex-wrap">
            <div>
              <h1 class="heading">Parcels</h1>
            </div>
            <div
              v-if="parcels.length"
              style="margin-top: 13px; margin-left: 10px; margin-bottom: 5px"
            >
              <v-btn-toggle v-model="view" color="primary" mandatory>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      value="card"
                      @click="changeView('card')"
                    >
                      <v-icon>mdi-select-group</v-icon>
                    </v-btn>
                  </template>
                  <span>Card View</span>
                </v-tooltip>
                <v-tooltip bottom v-if="parcels.length >= 3">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      value="list"
                      @click="changeView('list')"
                    >
                      <v-icon>mdi-table-of-contents</v-icon>
                    </v-btn>
                  </template>
                  <span>List View</span>
                </v-tooltip>

                <v-tooltip bottom v-if="showMapView">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      value="map"
                      @click="changeView('map')"
                    >
                      <v-icon>mdi-map</v-icon>
                    </v-btn>
                  </template>
                  <span>Map View</span>
                </v-tooltip>
              </v-btn-toggle>
              <v-menu
                v-if="view == 'map'"
                :close-on-content-click="false"
                v-model="dialogs.mapSetting"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="map-setting" icon>
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <map-settings
                  @close="dialogs.mapSetting = false"
                ></map-settings>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-btn color="primary" @click="dialogs.createParcel = true" small>
            New<span class="d-none d-sm-flex">Parcel</span>
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
      </div>
    </v-container>
    <div>
      <v-container fluid>
        <peeps-alert-animation
          ref="animationGlobal"
          message="Parcel update was Successful!"
        >
        </peeps-alert-animation>

        <v-slide-x-transition>
          <card-view @update="animate" v-show="view == 'card'"></card-view>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <list-view v-show="view == 'list'"></list-view>
        </v-slide-x-transition>
      </v-container>

      <v-slide-x-transition>
        <map-view v-if="view == 'map'"></map-view>
      </v-slide-x-transition>

      <v-container fluid>
        <div class="text-center mt-9" v-if="!parcels.length && !loading">
          <v-alert color="info" class="peeps--font" text>
            Please use the NEW PARCEL button to Add a parcel to your Peeps
            Portal.</v-alert
          >
        </div>
      </v-container>
    </div>
  </v-main>
</template>

<script>
import parcel from '@/mixins/parcel'
import ParcelCreate from '@/components/Parcel/CreateParcel'
import CardView from '@/components/Parcel/CardView'
import ListView from '@/components/Parcel/ListView'
import MapView from '@/components/Parcel/MapView'
import PeepsHooray from '@/components/PeepsHooray'
import MapSettings from '../../components/Parcel/MapSettings'
import { mapGetters } from 'vuex'
import Moment from 'moment'
export default {
  metaInfo: {
    title: 'Parcel'
  },
  mixins: [parcel],
  components: {
    ParcelCreate,
    CardView,
    ListView,
    PeepsHooray,
    MapView,
    MapSettings
  },
  data: () => ({
    dialogs: {
      createParcel: false,
      hoorayDialog: false,
      mapSettings: false
    },
    currentParcel: null,
    viewModes: ['card', 'list', 'map']
  }),
  async mounted() {
    this.parseViewMode()
    let user = await this.currentUser()
    if (user) {
      if (!this.parcels.length) {
        await this.getParcels(user.uid)
      }
    }
    this.loading = false
    this.storeParcelTypes()
    this.checkParcelId()
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters.parcelLoading
      },
      set(val) {
        this.$store.commit('parcelLoading', val)
      }
    },
    view: {
      get() {
        return this.$store.getters.viewMode
      },
      set(mode) {
        if (this.$route.query.view && this.$route.query.view != mode) {
          return
        }
        this.$store.commit('viewMode', mode)
      }
    },
    showMapView() {
      return this.parcels.some(e => {
        return e.center || e.geojson
      })
    },
    ...mapGetters(['parcels', 'latestParcel', 'featuresList'])
  },
  methods: {
    parcelCreated() {
      this.dialogs.createParcel = false
      if (this.$route.query.parcelId) {
        this.$router.push('/parcels')
      }
      setTimeout(() => {
        this.dialogs.hoorayDialog = true
      }, 500)
    },
    parseViewMode() {
      let mode = this.$route.query.view
      if (this.viewModes.some(e => e == mode)) {
        this.view = mode
      }
    },
    changeView(mode) {
      if (this.$route.query.view != mode) {
        this.$router.push(this.$route.path + `?view=${mode}`)
      }
    },
    animate() {
      this.$refs.animationGlobal.animate()
    },
    featureLink(feature) {
      if (!this.latestParcel) {
        return ''
      } else {
        return `/parcels/${this.latestParcel.id}/${feature.url}`
      }
    },
    checkParcelId() {
      let parcelId = this.$route.query.parcelId
      if (!parcelId) {
        return
      }
      this.dialogs.createParcel = true
    },
    async getParcelGeoData(data) {
      if (data.timestampGeoUpdated) {
        const date = this.parseTimestamp(data.timestampGeoUpdated)
        if (date) {
          const diff = Moment.duration(Moment(new Date()).diff(date)).asHours()
          if (diff <= 1) {
            return
          }
        }
      }

      this.updateParcelGeoData(data.id)
    },
    onParcelChange() {
      this.parcels.map(parcel => {
        this.getParcelGeoData(parcel)
      })
    }
  },
  watch: {
    parcels: {
      handler: 'onParcelChange'
    }
  }
}
</script>

<style lang="sass" scoped>
.map-setting
  margin-top: -10px !important
</style>
