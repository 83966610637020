<template>
  <div class="map-view-container">
    <v-dialog v-model="dialogs.card" max-width="600">
      <parcel-detail
        :data="editParcel"
        @close="dialogs.card = false"
        @update="updateActiveParcel"
        :showClose="true"
      >
      </parcel-detail>
    </v-dialog>
    <section>
      <div
        class="action ml-2"
        v-show="parcelsFiltered.length < 7 && parcelsFiltered.length > 1"
      >
        <v-speed-dial v-model="jumpTo" direction="right" absolute dark fab>
          <template v-slot:activator>
            <v-btn
              v-model="jumpTo"
              :color="
                mapStyle == MAP_CONFIG.styles.satellite ? 'black' : 'primary'
              "
              :dark="mapStyle == MAP_CONFIG.styles.satellite"
            >
              Jump-To
              <v-icon>{{
                jumpTo ? 'mdi-chevron-left' : 'mdi-chevron-right'
              }}</v-icon>
            </v-btn>
          </template>
          <v-btn
            v-if="parcelsFiltered.length > 1"
            small
            class="action-button text-capitalize"
            :color="
              mapStyle == MAP_CONFIG.styles.satellite ? 'black' : 'primary'
            "
            :dark="mapStyle == MAP_CONFIG.styles.satellite"
            @click="showPrevParcel()"
          >
            <span>
              <span
                >{{ removePadding(prevParcelData().number) }}
                <v-icon>{{ icons.parcelButton }}</v-icon>
              </span>
            </span>
          </v-btn>
          <v-btn
            v-if="parcelsFiltered.length > 2"
            small
            class="action-button text-capitalize"
            :color="
              mapStyle == MAP_CONFIG.styles.satellite ? 'black' : 'primary'
            "
            :dark="mapStyle == MAP_CONFIG.styles.satellite"
            @click="showNextParcel()"
          >
            <span>
              <span
                >{{ removePadding(nextParcelData().number) }}
                <v-icon>{{ icons.parcelButton }}</v-icon>
              </span>
            </span>
          </v-btn>
        </v-speed-dial>
      </div>
      <mgl-map
        hash
        ref="map"
        class="map"
        v-if="parcelsFiltered.length"
        :mapStyle="mapStyle"
        :zoom="MAP_ZOOM"
        :center="getCenter"
        @load="onMapLoad"
      >
        <mgl-scale-control
          v-if="scaleFilter"
          position="bottom-right"
        ></mgl-scale-control>
        <MapCoordinates />
      </mgl-map>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MglMap, MglScaleControl } from 'vue-mapbox'
import { lngLatFormatter } from '../../utils/map'
import map from '../../mixins/map'
import parcel from '../../mixins/parcel'
import user from '../../mixins/user'
import MapCoordinates from './MapCoordinates.vue'
export default {
  mixins: [map, parcel, user],
  components: {
    MglMap,
    MglScaleControl,
    ParcelDetail: () => import('./Detail'),
    MapCoordinates
  },
  data() {
    return {
      dialogs: {
        card: false
      },
      icons: {
        parcelButton: 'mdi-crosshairs-gps'
      },
      activeParcelIndex: 0,
      MAP_ZOOM: 16,
      DEFAULT_CENTER: [-70.047163, 41.987377],
      editParcel: {},
      zoom: false,
      showControl: true,
      sources: {
        parcel: 'parcels'
      },
      layers: {
        circle: 'parcel-circle',
        polygon: 'parcel-fill',
        outline: 'outline',
        label: 'parcel-label'
      },
      jumpTo: false,
      initLoad: true
    }
  },
  beforeMount() {
    let cfg = localStorage.getItem('mapConfig')

    if (!cfg) {
      return
    }
    try {
      cfg = JSON.parse(cfg)
    } catch (err) {
      console.log(err)
      return
    }

    this.$store.commit('setMapConfigObj', cfg)
    if (cfg.hash) {
      if (!this.$route.query.ignoreHash) {
        window.location.hash = cfg.hash
      }
      this.initLoad = false
    }
  },
  computed: {
    map: {
      get() {
        return this.$store.getters.map
      },
      set(map) {
        this.$store.commit('setMap', map)
      }
    },
    getCenter() {
      if (this.parcelsFiltered && this.parcelsFiltered[0]) {
        return this.parcelsFiltered[0].center
      }
      return this.DEFAULT_CENTER
    },
    ...mapGetters({
      parcels: 'parcels',
      user: 'user',
      mapConfig: 'mapConfig'
    }),

    mapKey() {
      return `${this.mapConfig.team} 
              ${this.mapConfig.acres} 
              ${this.mapConfig.scale} 
              ${this.mapConfig.reference} 
              ${this.mapConfig.tct} 
              ${this.mapConfig.tot}
              ${this.mapConfig.showParcelId}
            `
    },
    profile() {
      return this.user.profile
    },
    url() {
      return this.$route.fullPath
    },
    allParcels() {
      let parcels = [...this.parcelsFiltered]
      if (this.tctFilter) {
        parcels.push(...this.tctParcels)
      }
      if (this.totFilter) {
        parcels.push(...this.totParcels)
      }
      return parcels
    },
    geoJson() {
      let geoJson = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
      this.allParcels.forEach(i => {
        if (i.center && !i.geojson) {
          geoJson.data.features.push(this.parcelCircleGeojson(i))
        }
        if (i.geojson) {
          geoJson.data.features.push(this.parcelPolygonGeojson(i))
        }
      })
      return geoJson
    },
    activeParcel() {
      return this.parcelsFiltered[this.activeParcelIndex]
    }
  },
  methods: {
    getParcel(parcel) {
      return {
        ...this.$store.getters.parcels.filter(e => e.id == parcel.id)[0]
      }
    },
    setEditParcel(parcel) {
      this.editParcel = this.getParcel(parcel)
    },
    updateActiveParcel(data) {
      this.editParcel = { ...data }
    },
    showCardDialog(parcel) {
      this.setEditParcel(parcel)
      this.dialogs.card = true
    },
    prevParcelIndex() {
      let index = this.activeParcelIndex
      if (this.parcelsFiltered.length == 1) {
        return index
      }
      if (index == 0) {
        index = this.parcelsFiltered.length - 1
      } else {
        index--
      }
      return index
    },
    nextParcelIndex() {
      let index = this.activeParcelIndex
      if (this.parcelsFiltered.length == 1) {
        return index
      }
      if (index == this.parcelsFiltered.length - 1) {
        index = 0
      } else {
        index++
      }
      return index
    },
    nextParcelData() {
      return this.parcelsFiltered[this.nextParcelIndex()] || {}
    },
    prevParcelData() {
      return this.parcelsFiltered[this.prevParcelIndex()] || {}
    },
    prevParcel() {
      this.activeParcelIndex = this.prevParcelIndex()
    },
    nextParcel() {
      this.activeParcelIndex = this.nextParcelIndex()
    },
    zoomToParcel(index) {
      this.zoom = true
      this.$refs.map.map.flyTo({
        center: this.parcelsFiltered[index].center,
        essential: true
      })
    },
    resizeMap(map) {
      if (!map) {
        map = this.$refs.map.map
      }
      map.resize()
    },
    fitToBbox(map) {
      if (this.$route.query.bbox) {
        try {
          const defaultBbox = JSON.parse(this.$route.query.bbox)
          map.fitBounds(defaultBbox)
        } catch (err) {
          console.log(err)
        }
      }

      if (!this.initLoad) {
        return
      }

      const bboxList = this.bboxListFromParcels(this.parcelsFiltered)
      const bbox = this.getBbox(bboxList)

      map.fitBounds(bbox)
    },
    onMapLoad(map) {
      this.renderMap(map)
      this.plotNearbyParcels(map.map)
      this.fitToBbox(map.map)
    },
    renderMap(map) {
      if (map) {
        map = map.map
      } else {
        map = this.$refs.map.map
      }

      if (!map) {
        return
      }

      map.on('style.load', () => {
        this.renderParcels(this.$refs.map.map)
      })

      this.renderParcels(map)
      this.addNavigationControl(map)
      this.resizeMap(map)
    },
    zoomMap(level) {
      return new Promise(resolve => {
        if (!this.zoom) {
          return resolve()
        }
        this.zoom = false
        if (!level) {
          level = this.MAP_ZOOM
        }
        let currentLevel = this.$refs.map.map.getZoom()
        if (
          currentLevel < this.MAP_ZOOM - 1 ||
          currentLevel > this.MAP_ZOOM + 1
        ) {
          this.$refs.map.map.zoomTo(level, { duration: 1000 })
          setTimeout(() => {
            resolve()
          }, 1000)
        } else {
          resolve()
        }
      })
    },
    showNextParcel() {
      this.nextParcel()
      this.zoomToParcel(this.activeParcelIndex)
    },
    showPrevParcel() {
      this.prevParcel()
      this.zoomToParcel(this.activeParcelIndex)
    },

    addLabel(map) {
      if (this.showParcelIdFilter) {
        this.addLabelLayer(map, this.layers.label, this.sources.parcel)
      }
    },

    addParcel(map) {
      this.addParcelSource(map, this.sources.parcel, this.geoJson)
      this.addParcelCircle(map, this.layers.circle, this.sources.parcel)
      this.addParcelPolygon(map, this.layers.polygon, this.sources.parcel)
      this.addParcelOutline(map, this.layers.outline, this.sources.parcel)
    },

    removeParcels(map) {
      Object.keys(this.layers).forEach(l => {
        this.removeLayer(map, this.layers[l])
      })
      this.removeSource(map, this.sources.parcel)
    },

    listenParcelClick(map) {
      map.on('click', 'parcel-fill', e => {
        const owner = e.features[0].properties.owner
        if (owner == 'tct' || owner == 'tot') {
          return
        }
        let index = e.features[0].id
        this.showCardDialog(this.parcels[index])
      })
    },

    renderParcels(map) {
      if (!map) {
        console.error('map is required')
        return
      }

      this.removeParcels(map)
      this.addParcel(map)
      this.addParcelHoverEffect(map, this.layers.polygon, this.sources.parcel)
      // this.addParcelPopup(map, this.layers.polygon)
      this.addLabel(map)
      this.listenParcelClick(map)

      map.on('click', e => {
        this.$copyText(lngLatFormatter(e, false))
      })

      map.on('moveend', () => {
        this.zoomMap()
        this.saveMapConfig()
      })
      this.resizeMap(map)
    },
    async plotNearbyParcels(map) {
      await this.getNearbyParcels(this.parcelsFiltered)
      this.renderParcels(map)
    },
    saveMapConfig() {
      const hash = window.location.hash
      this.$store.commit('setMapConfig', { key: 'hash', value: hash })
    }
  },
  watch: {
    url: {
      handler: 'resizeMap'
    },
    mapKey() {
      const map = this.$refs.map
      if (map && map.map) {
        this.renderParcels(this.$refs.map.map)
        this.saveMapConfig()
      }
    }
  }
}
</script>

<style lang="sass">
.map
  width: 100%
  height: 83vh

.action
  margin-top: 0.5rem
  position: absolute
  z-index: 1
  opacity: 0.5

.map-styles
  position: absolute
  bottom: 0
  left: 0
  z-index: 3
  opacity: 0.5
.map-view-container
  margin-top: -1.8rem !important
</style>
