import { teamRef } from '@/firebase/db'
export default {
  mounted() {
    this.setDefaultTeams()
  },
  methods: {
    async setDefaultTeams() {
      if (this.$store.getters.teams) {
        return
      }
      let teams = await teamRef.doc('default').get()
      teams = teams.data()
      this.$store.commit('teams', teams)
    }
  }
}
